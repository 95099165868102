import { CheckOutlined } from "@ant-design/icons";
import { Button, ConfigProvider } from "antd";
import { ButtonProps } from "antd/lib/button";

import { CommonSymbology } from "../commonSymbology";

type LikeButtonStatus = "notLiked" | "liked" | "matched" | "likedAllJobs";

interface LikeButtonProps extends ButtonProps {
  likeButtonStatus: LikeButtonStatus;
  filled?: boolean;
}

const LikeButton = ({
  likeButtonStatus,
  loading,
  onClick,
  href,
  filled,
  ...props
}: LikeButtonProps) => {
  let IconTag = <CommonSymbology.Like />;
  if (filled) {
    IconTag = <CommonSymbology.Like singleTone />;
  }

  let colorBorder = "#eb7a15";
  let buttonText = "Like";
  let disabled = false;

  switch (likeButtonStatus) {
    case "liked":
      IconTag = <CheckOutlined />;
      buttonText = "Likeado";
      colorBorder = "#d9d9d9";
      disabled = true;
      break;
    case "matched":
      IconTag = <CheckOutlined />;
      buttonText = "Match";
      colorBorder = "#d9d9d9";
      disabled = true;
      break;
    case "likedAllJobs":
      IconTag = <CommonSymbology.Like twoToneColor="#b8b8b8" />;
      colorBorder = "#d9d9d9";
      disabled = true;
      break;
    default:
      break;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#eb7a15",
          colorPrimaryHover: "#eb7a15",
          colorPrimaryActive: "#eb7a15",
          colorText: "#eb7a15",
          colorBorder: filled ? undefined : colorBorder,
        },
      }}
    >
      <Button
        {...props}
        block
        loading={loading}
        disabled={disabled}
        href={href}
        icon={IconTag}
        onClick={onClick}
        type={filled ? "primary" : "default"}
      >
        {buttonText}
      </Button>
    </ConfigProvider>
  );
};

export default LikeButton;
