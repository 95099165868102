import React from "react";
import { IconBaseProps } from "react-icons";
import { TbBriefcase2 } from "react-icons/tb";
import {
  ApiTwoTone,
  CrownTwoTone,
  ExperimentTwoTone,
  FlagOutlined,
  HeartOutlined,
  HeartTwoTone,
  ShareAltOutlined,
  ThunderboltTwoTone,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

import commonColors from "./commonColors";

const Like = (props: AntdIconProps & { singleTone?: boolean }) => {
  const { singleTone, ...rest } = props;

  if (singleTone) {
    return <HeartOutlined {...rest} />;
  }

  return <HeartTwoTone twoToneColor={commonColors.like} {...rest} />;
};

const SuperLike = (props: AntdIconProps) => (
  <ThunderboltTwoTone twoToneColor={commonColors.superLike} {...props} />
);

const Match = (props: AntdIconProps) => (
  <ApiTwoTone twoToneColor={commonColors.match} {...props} />
);

const Premium = (props: AntdIconProps) => (
  <CrownTwoTone twoToneColor={commonColors.premium} {...props} />
);

const Compatibility = (props: AntdIconProps) => (
  <ExperimentTwoTone twoToneColor={commonColors.compatibility} {...props} />
);

const Lite = (props: AntdIconProps) => <FlagOutlined {...props} />;

const Share = (props: AntdIconProps) => (
  <ShareAltOutlined style={{ color: commonColors.shareGreen }} {...props} />
);

const WhatsApp = (props: AntdIconProps) => (
  <WhatsAppOutlined
    style={{ color: commonColors.whatsAppGreen, ...props.style }}
    {...props}
  />
);

const ThirdPartyJob = (props: IconBaseProps) => (
  <TbBriefcase2
    style={{ ...props.style }}
    color={commonColors.thirdPartyJob}
    {...props}
  />
);

export const CommonSymbology = {
  Like,
  SuperLike,
  Match,
  Premium,
  Compatibility,
  Lite,
  Share,
  WhatsApp,
  ThirdPartyJob,
};
