import { Button, ConfigProvider } from "antd";
import { ButtonProps } from "antd/lib/button";

import { CommonSymbology } from "../commonSymbology";

type SuperLikeButtonStatus = "notSuperLiked" | "likedAllJobs";

interface SuperLikeButtonProps extends ButtonProps {
  superLikeButtonStatus?: SuperLikeButtonStatus;
}

const SuperLikeButton = ({
  superLikeButtonStatus,
  loading,
  onClick,
  style,
  href,
  ...props
}: SuperLikeButtonProps) => {
  let IconTag = <CommonSymbology.SuperLike />;
  let colorBorder = "#4096FF";
  const buttonText = "Super Like";
  let disabled = false;

  if (superLikeButtonStatus === "likedAllJobs") {
    IconTag = <CommonSymbology.SuperLike twoToneColor="#b8b8b8" />;
    colorBorder = "#d9d9d9";
    disabled = true;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4096FF",
          colorPrimaryHover: "#4096FF",
          colorPrimaryActive: "#4096FF",
          colorText: "#4096FF",
          colorBorder,
        },
      }}
    >
      <Button
        {...props}
        block
        style={style}
        loading={loading}
        disabled={disabled}
        href={href}
        icon={IconTag}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </ConfigProvider>
  );
};

export default SuperLikeButton;
