import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, ButtonProps, ConfigProvider } from "antd";

const DiscardButton = (props: ButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimaryHover: "#FB4C4F",
            colorPrimaryActive: "#FB4C4F",
          },
        },
      }}
    >
      <Button
        icon={<DeleteOutlined />}
        type="dashed"
        style={{ width: "50%" }}
        {...props}
      >
        Descartar
      </Button>
    </ConfigProvider>
  );
};

export default DiscardButton;
