import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { CalendarOutlined } from "@ant-design/icons";
import { Tag, Tooltip, Typography } from "antd";

import { useCommonJobCard } from "./CommonJobCardHook";

const { Text } = Typography;

const CommonJobCardFooter = () => {
  const { job } = useCommonJobCard();

  const getJobLocation = () => {
    if (job.place) {
      return (
        job.place.city?.name ??
        job.place.country?.name ??
        job.place.state?.name ??
        job.place.description
      );
    }

    return "Remoto";
  };

  const getJobLocationTooltip = () => {
    if (job.place) {
      return job.place.city?.name
        ? `${job.place.city?.name}, ${job.place.country?.name}`
        : `${job.place.state?.name}, ${job.place.country?.name}`;
    }

    return "Trabajo remoto";
  };

  const getExpirationText = () => {
    const expirationDate = new Date(job.jobSlot?.expirationDate);
    const currentDate = new Date();
    const diff = expirationDate.getTime() - currentDate.getTime();
    const diffInDays = diff / (1000 * 3600 * 24);
    if (diffInDays < 1) {
      return "Expira hoy";
    }

    const days = Math.floor(diffInDays);
    return `Expira en ${days} día${days > 1 ? "s" : ""}`;
  };

  const getExpirationTooltip = () => {
    const expirationDate = new Date(job.jobSlot?.expirationDate);
    return `Expira el ${expirationDate.toLocaleDateString(
      "es-Es"
    )} a las ${expirationDate.toLocaleTimeString("es-Es", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "50%",
        }}
      >
        <Tooltip title={getJobLocationTooltip()}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              marginRight: 10,
            }}
          >
            <MdOutlineLocationOn
              style={{
                marginRight: 4,
                fontSize: 12,
                top: 1,
                position: "relative",
              }}
            />
            <Text style={{ fontSize: 12 }}>{getJobLocation()}</Text>
          </div>
        </Tooltip>
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {job.jobSlot ? (
          <Tooltip title={getExpirationTooltip()}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
            >
              <CalendarOutlined
                style={{
                  marginRight: 4,
                  fontSize: 12,
                  bottom: 1,
                  position: "relative",
                }}
              />
              <Text style={{ fontSize: 12 }}>{getExpirationText()}</Text>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="La vacante no esta activa en este momento.">
            <Tag color="red">Inactiva</Tag>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CommonJobCardFooter;
