"use client";

import React, { createContext, ReactNode, useContext } from "react";
import { Job } from "@koble/graphql";
import { MenuProps } from "antd";
import { ItemType } from "antd/es/menu/interface";

export interface CommonJobCardContextProps {
  job: Job;
  profileImagePlaceholder: string;
  extraActionsMenu?: Omit<MenuProps, "items"> & {
    items?: (ItemType & { loading?: boolean })[];
  };
}

export const CommonJobCardContext = createContext<CommonJobCardContextProps>({
  job: {} as Job,
  profileImagePlaceholder: "",
  extraActionsMenu: {},
});

export const CommonJobCardProvider = ({
  children,
  ...props
}: CommonJobCardContextProps & { children: ReactNode }) => {
  return (
    <CommonJobCardContext.Provider value={{ ...props }}>
      {children}
    </CommonJobCardContext.Provider>
  );
};

export const useCommonJobCard = () => useContext(CommonJobCardContext);
