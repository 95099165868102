import React from "react";
import { Tag, Tooltip } from "antd";

import { useCommonJobCard } from "./CommonJobCardHook";

const CommonJobCardBody = () => {
  const { job } = useCommonJobCard();

  const lineHeights = 1.4;
  const fontSize = 12;
  const numberOfLines = 2;
  const spanHeight = fontSize * numberOfLines * lineHeights;

  return (
    <div>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          fontSize,
          lineHeight: `${lineHeights}em`,
          height: `${spanHeight}px`, // Fixed height for 2 lines
          width: "100%", // Ensuring the span takes the full width
        }}
      >
        {job.about}
      </span>
      <div style={{ marginTop: 8 }}>
        {job.workScheduleType && <Tag>{job.workScheduleType.spanishName}</Tag>}
        {job.skillInCommonWithUserStudentId &&
          job.skillInCommonWithUserStudentId.length > 0 && (
            <Tooltip
              title={job.skillInCommonWithUserStudentId
                .map((skill) => skill.spanishName)
                .join(", ")}
            >
              <Tag>
                {job.skillInCommonWithUserStudentId.length} habilidad
                {job.skillInCommonWithUserStudentId.length > 1 ? "es " : " "}
                en común
              </Tag>
            </Tooltip>
          )}
      </div>
    </div>
  );
};

export default CommonJobCardBody;
