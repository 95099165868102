import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";

const style = { textDecoration: "none", color: "inherit" };

const CommonAdaptiveLink = ({
  children,
  ssrUrl,
  clientUrl,
  scroll,
  ...props
}: {
  children: ReactNode;
  ssrUrl: string;
  clientUrl?: string;
} & Omit<LinkProps, "href">) => {
  if (!clientUrl) {
    return (
      <a href={ssrUrl} style={style}>
        {children}
      </a>
    );
  }

  return (
    <Link
      scroll={scroll}
      href={clientUrl}
      legacyBehavior
      style={style}
      {...props}
    >
      <a href={ssrUrl} style={style}>
        {children}
      </a>
    </Link>
  );
};

export default CommonAdaptiveLink;
